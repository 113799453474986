<template>
  <main>
    <section>
      <h2>Seite nicht gefunden</h2>
      <p>Die angeforderte Seite konnte leider nicht gefunden werden!</p>
    </section>
  </main>

  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "PageView",
  components: { Footer },
};
</script>

<style lang="css" scoped></style>
