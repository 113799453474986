<template>
  <Header />
  <router-view :key="$route.fullPath" />
</template>

<script>
import Header from "@/components/Header.vue";
import "@/assets/css/fonts.css";
import "@/assets/css/style.css";
import "@/assets/css/media.css";

export default {
  components: { Header },
};
</script>

<style lang="css">
.header {
  position: relative;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.header img {
  position: absolute;
  top: 16px;
  right: 32px;
  width: 350px;
  z-index: 200;
}

@media screen and (min-width: 980px) {
  #app {
    width: 1000px;
  }
}
</style>
