<template>
  <button class="NAVtrigger" @click="toggleMenu()">
    <img src="@/assets/icons/list.svg" alt="Menü öffnen" />
  </button>
  <nav :class="{ show: show }">
    <div class="close">
      <button @click="toggleMenu()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="bi bi-x-circle"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
          />
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
    </div>

    <div class="content">
      <h1>WelzinerKultur<span>Sommer</span></h1>
      <template
        v-for="(link, index) in $store.state.navigation"
        :key="link.link"
      >
        <a
          v-if="link.navigation"
          @click="
            (e) => {
              navigate(e, index);
            }
          "
          class="nolink"
        >
          {{ link.title }}
        </a>
      </template>
    </div>

    <div class="bg">
      <img src="@/assets/rays.svg" />
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationContainer",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleMenu() {
      this.show = !this.show;
      if (this.show) {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "auto";
      }
    },
    navigate(e, id) {
      e.preventDefault();
      this.show = false;
      document.querySelector("body").style.overflow = "auto";
      this.$router.push({ path: this.$store.state.navigation[id].path });
    },
  },
};
</script>

<style lang="css">
nav {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  color: white;
  background: var(--blue);
  z-index: 100000000;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  font-weight: 600;
  transition: transform 1s;
  transform: translateX(100%);
  padding: 24px;
  overflow: hidden;
}

.show {
  transform: translateX(0);
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
}

.close button {
  padding: 0;
  background: inherit;
  border: 0;
  outline: 0;
}

.close svg {
  width: 32px;
  height: 32px;
  fill: white;
}

nav .content {
  position: absolute;
  bottom: 24px;
  line-height: 2.4rem;
  z-index: 100;
}

nav a {
  display: block;
  white-space: nowrap;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

nav .bg {
  position: absolute;
  top: -20%;
  left: -30%;
  transform-origin: 50%;
  z-index: 1;
}

nav .bg img {
  height: 60vh;
}

.NAVtrigger {
  position: absolute;
  top: 6px;
  right: 6px;
  border: 0;
  padding: 0;
  z-index: 99;
  font-family: "SuperClarendon";
  font-weight: 600;
  font-size: 1.4rem;
  user-select: none;
  cursor: pointer;
  background: white;
}

.NAVtrigger img {
  width: 36px;
  height: 36px;
}

nav h1 {
  font-size: 1.4rem;
}

nav h1 span {
  color: #f9b200;
}

@media screen and (min-width: 900px) {
  .NAVtrigger {
    display: none;
  }

  nav {
    position: absolute;
    color: black;
    left: 0px;
    top: 48px;
    background: none;
    transform: translateX(0);
    padding: 0;
    height: auto;
  }

  nav .content {
    display: grid;
    grid-template-columns: repeat(5, min-content);
    grid-gap: 12px;
    padding: 0;
    position: relative;
    bottom: 0;
    background: none;
  }

  nav a {
    display: inline;
    user-select: none;
    white-space: nowrap;
    color: var(--blue);
    font-family: SuperClarendon;
    font-size: 1.2rem;
    text-decoration: none;
    cursor: pointer;
  }

  nav a:hover {
    color: var(--orange);
  }

  nav a.active {
    color: var(--orange) !important;
  }

  nav .close,
  nav .bg,
  nav h1 {
    display: none;
  }
}
</style>
