<template>
  <img src="@/assets/header.svg" class="desktop" />
  <img src="@/assets/header_hoch.svg" class="mobile" />
  <main v-if="content" class="home">
    <section class="content">
      <div v-html="content.content" />
    </section>

    <section class="news">
      <h2 class="category" @click="$router.push({ path: '/blog' })">
        <span>Letzte Neuigkeiten</span>
      </h2>
      <BlogListSmall />
    </section>
  </main>

  <ul class="weekends">
    <li v-for="weekend in selection" :key="weekend.title">
      <router-link
        :to="{ name: 'programm', params: { end: weekend.end } }"
        class="nolink"
      >
        {{ weekend.title }}
      </router-link>
    </li>
  </ul>

  <div class="col_events">
    <h2 class="category" @click="$router.push({ path: '/programm' })">
      <span>Nächste Veranstaltungen</span>
    </h2>
    <ProgrammList :loader="false" :limit="4" />
  </div>
  <Map :show="1" />
  <Footer />
</template>

<script>
import { mapActions } from "vuex";
import ProgrammList from "@/components/ProgrammList.vue";
import BlogListSmall from "@/components/BlogListSmall.vue";
import Footer from "@/components/Footer.vue";
import Map from "@/components/Map.vue";

export default {
  name: "PageView",
  components: {
    ProgrammList,
    BlogListSmall,
    Map,
    Footer,
  },
  data() {
    return {
      content: undefined,
      selection: [
        { title: "Alle Veranstaltungen", query: "" },
        { title: "8./9. Juli 2022", end: "2022-07-09", query: "" },
        { title: "15./16. Juli 2022", end: "2022-07-16", query: "" },
        { title: "22./23. Juli 2022", end: "2022-07-23", query: "" },
        { title: "29./30. Juli 2022", end: "2022-07-30", query: "" },
        { title: "5./6. August 2022", end: "2022-08-06", query: "" },
        { title: "12./13. August 2022", end: "2022-08-13", query: "" },
      ],
    };
  },
  methods: {
    ...mapActions(["db"]),
    fetch() {
      this.db({
        table: "content",
        payload: { q: { slug: { $eq: this.$route.path } } },
      }).then((res) => {
        this.content = res.data.results[0];
        this.$store.state.showPage = true;
      });
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="css">
.desktop {
  display: none;
}

.weekends {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 48px;
}

.weekends li {
  background: var(--orange);
  padding: 12px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 6px;
}

.home {
  display: grid;
  grid-gap: 36px;
  padding: 12px;
  background: var(--grey);
  margin-bottom: 6px;
}

@media screen and (min-width: 1150px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  .home {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 36px;
    padding: 24px;
    background: var(--grey);
    margin-bottom: 6px;
  }

  .content h2 {
    font-size: 2.8rem;
    line-height: 3.4rem;
    margin-bottom: 12px;
    font-weight: 400;
    max-width: 70%;
  }

  .news {
    background: white;
    padding: 12px;
  }
}
</style>
