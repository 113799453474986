<template>
  <section class="newsSmall" v-if="items">
    <div v-for="item in items" :key="item.id" class="entry">
      <Image v-if="image(item)" :id="image(item)" :title="item.title" />

      <h3>{{ item.title }}</h3>
      <div class="date">{{ created(item.created) }}</div>
      <div class="text" v-html="text(item.content)"></div>

      <router-link :to="{ path: '/blog/' + item.slug }" class="button nolink">
        Weiterlesen
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import dayjs from "dayjs";
import Image from "@/components/Image.vue";

export default {
  name: "NewsContainer",
  props: ["loader", "hide", "limit", "small"],
  components: { Image },
  data() {
    return {
      items: undefined,
      meta: undefined,
      page: 1,
    };
  },
  computed: {},
  methods: {
    ...mapActions(["db"]),
    image(item) {
      if (item.images != "") return item.images.split(",")[0];
      else return false;
    },
    created(date) {
      return dayjs(date).format("DD. MMMM YYYY");
    },
    text(item) {
      const text = item.split("</p>")[0];
      return text;
    },
    fetch(push) {
      this.db({
        table: "blog",
        payload: { o: { created: -1 }, l: 2, p: this.page },
      })
        .then((res) => {
          if (push) {
            res.data.results.forEach((item) => this.items.push(item));
            this.meta = res.data.meta;
          } else {
            this.meta = res.data.meta;
            this.items = res.data.results;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  mounted() {
    this.fetch(false);
  },
};
</script>

<style lang="css">
.newsSmall .entry {
  margin-top: 12px;
}

.newsSmall picture {
  margin-bottom: 6px;
}

.newsSmall h3 {
  font-size: 1rem;
  line-height: 1.6rem;
  margin-bottom: 0;
}

.newsSmall .button {
  display: inline-block;
  background: var(--orange);
  color: white;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 0.8rem;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.newsSmall .buttonMore {
  background: white;
  box-sizing: border-box;
  display: inline-grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 6px;
  width: auto;
  margin: 0 auto;
  display: none;
}

.newsSmall .buttonMore div {
  display: inline;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--blue);
}

.newsSmall .date {
  display: inline-block;
  color: #333;
  font-size: 1rem;
  white-space: nowrap;
  font-style: italic;
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 0.8rem;
}

.newsSmall p {
  font-size: 0.9rem;
  line-height: 1.6rem;
}

.newsSmall .date:before {
  content: "— ";
}
</style>
