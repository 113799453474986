<template>
  <div class="loader" ref="loader">
    <div class="ldrgrid">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderContainer",
  methods: {
    createObserver() {
      let loader = this.$refs.loader;
      let observer;

      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      };

      observer = new IntersectionObserver(this.handleIntersect, options);
      observer.observe(loader);
    },
    handleIntersect(e) {
      if (e[0].intersectionRatio == 1) {
        this.$emit("load");
      }
    },
  },
  mounted() {
    this.createObserver();
  },
};
</script>

<style lang="css" scoped>
.loader {
  width: 100%;
  display: grid;
  box-sizing: border-box;
  place-items: center;
  margin: 48px 0;
}

.loader .ldrgrid {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}

.loader .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--blue);
  animation-name: loader;
  animation-duration: 0.6s;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  transition: transform;
}

.loader .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loader .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loader {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
</style>
