<template>
  <picture v-if="item">
    <template v-for="format in sizes" :key="format.size">
      <source
        v-if="item['size' + format.size]"
        :srcset="image + '-' + format.size + '.jpg'"
        :media="
          '(min-width: ' +
          format.min +
          'px) and (max-width: ' +
          format.max +
          'px)'
        "
        type="image/jpeg"
      />
      <!-- <source
        v-if="item['size' + format.size]"
        :srcset="image + '-' + format.size + '.webp'"
        :media="
          '(min-width: ' +
          format.min +
          'px) and (max-width: ' +
          format.max +
          'px)'
        "
        type="image/webp"
      /> -->
    </template>
    <img v-if="preview" :src="preview" :alt="'Bild: ' + title" @load="done" />
  </picture>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ImageLoader",
  props: ["id", "title"],
  data() {
    return {
      loaded: false,
      item: undefined,
      sizes: [
        { size: "400", min: 5, max: 399 },
        { size: "800", min: 400, max: 799 },
        { size: "1600", min: 800, max: 3200 },
      ],
    };
  },
  computed: {
    image() {
      if (this.item) return "/src/" + this.item.filename.split(".")[0];
      else return false;
    },
    preview() {
      if (this.item)
        return (
          "/src/" +
          this.item.filename.split(".")[0] +
          "-400." +
          this.item.filename.split(".")[1]
        );
      else return false;
    },
  },
  methods: {
    ...mapActions(["db"]),
    done() {},
  },
  created() {
    if (this.id && this.id != " ") {
      this.db({
        table: "images",
        payload: { q: { id: { $in: this.id } } },
      }).then((res) => {
        this.item = res.data.results[0];
      });
    }
  },
};
</script>

<style lang="css" scoped>
picture {
  box-sizing: border-box;
  width: 100%;
  display: block;
}

picture img {
  width: 100%;
  display: block;
}
</style>
