<template>
  <div class="map">
    <div class="blocker"></div>
    <iframe
      width="100%"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://www.openstreetmap.org/export/embed.html?bbox=12.026971578598024%2C53.51352103500926%2C12.03655242919922%2C53.52268770205742&amp;layer=mapnik&amp;marker=53.518104616440134%2C12.03176200389862"
    />
  </div>
</template>

<script>
export default {
  name: "OpenMap",
  data() {
    return {
      in: undefined,
      out: undefined,
      map: undefined,
    };
  },
  methods: {
    enableMap() {
      setTimeout(() => {
        document.querySelector(".blocker").style.display = "none";
      }, 1000);
    },
    disableMap() {
      document.querySelector(".blocker").style.display = "auto";
    },
  },
  mounted() {
    this.map = document.querySelector(".map");
    this.in = this.map.addEventListener("mouseover", this.enableMap);
    this.out = this.map.addEventListener("mouseout", this.disableMap);
  },
  beforeUnmount() {
    this.map.removeEventListener("mouseover", this.enableMap, true);
    this.map.removeEventListener("mouseout", this.disableMap, true);
  },
};
</script>

<style lang="css" scoped>
.map {
  position: relative;
  height: 350px;
  border-bottom: 3px solid white;
}

iframe {
  display: block;
  height: 350px;
  overflow: hidden;
}

@media screen and (min-width: 600px) {
  .map {
    position: relative;
    height: 400px;
  }

  .blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  iframe {
    display: block;
    height: 100%;
  }
}
</style>
