import { createStore } from "vuex";
import axios from "axios";

if (process.env.VUE_APP_API_DB) {
  console.log("API: DB: " + process.env.VUE_APP_API_DB);
  axios.defaults.headers.common["APIDB"] = process.env.VUE_APP_API_DB;
}

export default createStore({
  state: {
    loaded: false,
    showPage: false,
    navigation: [
      { title: "Über uns", path: "/ueber-uns", navigation: 1 },
      { title: "Neuigkeiten", path: "/blog", navigation: 1 },
      { title: "Programm", path: "/programm", navigation: 1 },
      { title: "Mitmachen", path: "/mitmachen", navigation: 1 },
      { title: "Anfahrt", path: "/anfahrt", navigation: 1 },
      { title: "Impressum", path: "/impressum", navigation: 0 },
      { title: "Datenschutzerklärung", path: "/datenschutz", navigation: 0 },
    ],
  },
  getters: {},
  mutations: {},
  actions: {
    async db({ state }, { table, action = "get", payload }) {
      state.loaded = false;
      const id = payload?.id ? payload.id : "";
      if (table) table = table + "/";
      if (!table) table = "";

      if (action == "get") payload = { params: payload };
      console.log("API:", action.toUpperCase(), table);
      const req = axios[action](process.env.VUE_APP_API + table + id, payload)
        .then((res) => {
          state.loaded = true;
          return res;
        })
        .catch((err) => {
          state.loaded = false;
          console.error("API:", err.response.data);
          return err.response;
        });

      // KEEP ALIVE IF LOGGED IN
      return req;
    },
  },
  modules: {},
});
