<template>
  <!-- NEWSLETTER -->
  <div class="newsletter" v-if="!registered">
    <h2>Newsletter</h2>
    <p>
      Immer informiert über alle Neuigkeiten rund um den Welziner Kultursommer!
    </p>
    <form @submit="register">
      <input type="text" placeholder="Vorname" required v-model="user.vname" />
      <input type="text" placeholder="Name" required v-model="user.name" />
      <input
        type="email"
        placeholder="E-Mail Adresse"
        required
        v-model="user.email"
      />
      <button class="newsletterSubmit">Anmelden</button>
    </form>
  </div>
  <div class="registered" v-else>
    <div>
      <h3>Newsletter Anmeldung</h3>
      <p>Vielen Dank für deine Anmeldung!</p>
      <button class="newsletterSubmit" @click="close">
        Fenster schliessen
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewsletterContainer",
  data() {
    return {
      registered: false,
      user: {
        email: "",
        name: "",
        vname: "",
      },
    };
  },
  methods: {
    ...mapActions(["db"]),
    register(e) {
      e.preventDefault();
      this.db({ action: "post", table: "newsletter", payload: this.user }).then(
        (res) => {
          if (res.status == 200) this.registered = true;
        }
      );
    },
    close() {
      this.registered = false;
      this.user = {
        email: "",
        name: "",
        vname: "",
      };
    },
  },
};
</script>

<style lang="css" scoped>
.newsletter {
  grid-area: newsletter;
  margin: 24px 0;
  z-index: 100;
}

.newsletter p {
  margin-top: 0;
}

.newsletter input {
  display: block;
  box-sizing: border-box;
  border: 0;
  padding: 12px;
  width: 100%;
  outline: 0;
  border-radius: 0;
  margin-bottom: 8px;
}

.newsletter button,
.registered button {
  display: block;
  padding: 12px 8px;
  box-sizing: border-box;
  width: 100%;
  font-weight: bold;
  border: 0;
  color: var(--blue);
  background: var(--orange);
}

.registered {
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}

.registered div {
  background: white;
  color: black;
  padding: 12px 18px;
}
</style>
