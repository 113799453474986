<template>
  <footer>
    <h1>WelzinerKultur<span>Sommer</span></h1>
    <div class="footerGrid">
      <!-- LINKS -->
      <div class="links">
        <template
          v-for="(link, index) in $store.state.navigation"
          :key="link.path"
        >
          <a
            @click="
              (e) => {
                navigate(e, index);
              }
            "
          >
            {{ link.title }}
          </a>
        </template>
      </div>

      <!-- ANFAHRT -->
      <div class="anfahrt">
        <div>
          <h2>Anfahrt</h2>
          <address>
            Gutshaus Welzin<br />
            An der Eiche 13<br />19386 Passow / OT Welzin
          </address>
          <div class="anfGrid">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-map-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"
                />
              </svg>
              <a
                href="https://www.odeg.de/fileadmin/user_upload/Unternehmensseite/linien-fahrplaene/liniennetz-fahrplaene/WEB_RB15_RB19_MAR2022_296x240_x76498.pdf"
                target="top"
                title="Auf Google Maps anzeigen"
              >
                RB19 Ausstieg Passow
              </a>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                />
              </svg>
              <a
                href="https://www.google.com/maps/place/Welziner+Kultursommer+2022/@53.5181284,12.0295827,17z/data=!4m13!1m7!3m6!1s0x47ac20d91472fa95:0xa3b22804b9e9baae!2sAn+d.+Eiche+13,+19386+Passow!3b1!8m2!3d53.5181284!4d12.0317766!3m4!1s0x47ac214927c3e9e5:0x8a4608467c3e3d26!8m2!3d53.5170871!4d12.033147"
                title="Auf Google Maps anzeigen"
                target="top"
              >
                Google Maps
              </a>
            </div>
          </div>
        </div>
        <img src="@/assets/map.png" alt="Karte" />
      </div>

      <Newsletter />
      <Social />

      <div class="bg">
        <img src="@/assets/rays.svg" alt="Kultursommer Logo" />
      </div>
    </div>
  </footer>
  <div class="bvs">
    <img src="@/assets/bvs.png" alt="Gefördert von Neustart Kultur" />
  </div>
</template>

<script>
import Social from "@/components/Social.vue";
import Newsletter from "@/components/Newsletter.vue";

export default {
  name: "FooterContainer",
  components: {
    Social,
    Newsletter,
  },
  methods: {
    navigate(e, id) {
      e.preventDefault();
      this.$router.push({ path: this.$store.state.navigation[id].path });
    },
  },
};
</script>

<style lang="css">
footer {
  position: relative;
  background: #01388c;
  color: white;
  padding: 16px;
  line-height: 2rem;
  overflow: hidden;
}

footer h1 {
  font-size: 1.2rem;
}

footer h1 span {
  color: #f9b200;
}

footer h2 {
  display: inline-block;
  font-weight: 400;
  font-size: 1.2rem;
  border-bottom: 2px solid white;
  padding-bottom: 3px;
}

footer a {
  display: block;
  border-bottom: 1px solid white;
}

.footerGrid {
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    "links"
    "anfahrt"
    "newsletter"
    "social";
  margin-top: 16px;
}

.links {
  grid-area: links;
  line-height: 2.2rem;
}

.links a:after {
}

.anfahrt {
  grid-area: anfahrt;
  margin-top: 24px;
}

.anfahrt .anfGrid {
  margin-top: 12px;
  line-height: 0.9rem;
}

.anfahrt .anfGrid div {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 8px;
  margin-bottom: 16px;
}

.anfahrt .anfGrid div a {
  border: 0;
  font-size: smaller;
  display: inline !important;
  white-space: nowrap;
}

.anfahrt img {
  display: block;
  margin-top: 24px;
  width: 80%;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 12px;
}

footer .bg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120vw;
  transform: translateY(45%) translateX(45%);
  z-index: 1;
}

.bvs {
  margin: 12px 0;
  margin-bottom: 24px;
}

.bvs img {
  width: 100%;
}

@media screen and (min-width: 980px) {
  footer {
    padding: 32px;
    padding-bottom: 0;
  }

  footer h1 {
    font-size: 1.8rem;
  }

  footer a {
    display: block;
    max-width: 200px;
    cursor: pointer;
  }

  footer a:hover {
    font-weight: 600;
  }

  .none {
    grid-area: none;
  }

  .footerGrid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "anfahrt newsletter"
      "anfahrt none"
      "social none"
      "links links";
    grid-gap: 32px;
    margin-top: 16px;
  }

  .anfahrt {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .anfahrt img {
    width: auto;
    max-height: 350px;
  }

  footer .bg {
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 350px;
    transform: translateY(50%);
    z-index: 1;
  }

  footer .bg img {
    width: 800px;
  }

  footer .links {
    display: inline-grid;
    grid-template-columns: repeat(7, min-content);
    margin-bottom: 0;
  }

  footer .links a {
    z-index: 10000000;
    font-size: 0.9rem;
    border-bottom: none;
    white-space: nowrap;
    font-weight: 600;
    padding: 0 15px;
    border-left: 2px solid white;
  }

  footer .links a:last-child {
    border-right: 2px solid white;
  }

  footer .links a:hover {
    background: white;
    color: var(--blue);
  }

  .bvs {
    margin-bottom: 24px;
  }

  .bvs img {
  }
}
</style>
