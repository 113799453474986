<template>
  <header>
    <router-link to="/" class="nolink mobile" title="Zur Startseite">
      <img
        src="@/assets/logotypo.svg"
        alt="Welziner Kultursommer"
        class="logo"
      />
    </router-link>

    <img
      src="@/assets/WKS_logo.png"
      alt="Welziner Kultursommer"
      class="desktop logo"
    />
    <router-link
      to="/"
      class="nolink clicker1 desktop"
      title="Zur Startseite"
    />
    <router-link
      to="/"
      class="nolink clicker2 desktop"
      title="Zur Startseite"
    />
    <Navigation />
  </header>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  components: { Navigation },
  name: "HeaderContainer",
};
</script>

<style lang="css" scoped>
header {
  position: relative;
  margin-top: 12px;
  margin-bottom: -10px;
  background: none;
}

header .logo {
  width: 100%;
  margin-top: 48px;
  margin-bottom: 12px;
}

header .desktop {
  display: none;
}

header h1 {
  color: var(--blue);
  margin-bottom: 18px;
  font-size: 2.8rem;
  line-height: 2.8rem;
}

header h1 span {
  color: var(--orange);
}

@media screen and (min-width: 900px) {
  header {
    position: relative;
    margin-top: 96px;
    margin-bottom: 3px;
    height: 96px;
  }

  header .mobile {
    display: none;
  }

  header .desktop {
    display: block;
  }

  header .logo {
    position: absolute;
    margin: 0;

    width: 110%;
    top: -180px;
  }

  .clicker1 {
    position: fixed;
    top: 0;
    display: block;
    height: 140px;
    width: 980px;
    cursor: pointer;
  }

  .clicker2 {
    position: absolute;
    right: -76px;
    top: -156px;
    display: block;
    height: 360px;
    width: 360px;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
