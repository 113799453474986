import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Notfound from "../views/Notfound.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/programm/:slug?",
    name: "programm",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/Programm.vue"),
  },
  {
    path: "/blog/:slug?",
    name: "blog",
    component: () => import(/* webpackChunkName: "page" */ "../views/Blog.vue"),
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: () => import(/* webpackChunkName: "page" */ "../views/Page.vue"),
  },
  {
    path: "/anfahrt",
    name: "anfahrt",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/Anfahrt.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () => import(/* webpackChunkName: "page" */ "../views/Page.vue"),
  },
  {
    path: "/mitmachen",
    name: "mitmachen",
    component: () => import(/* webpackChunkName: "page" */ "../views/Page.vue"),
  },
  {
    path: "/ueber-uns",
    name: "ueberuns",
    component: () => import(/* webpackChunkName: "page" */ "../views/Page.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: Notfound,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

export default router;
