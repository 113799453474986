<template>
  <section class="" v-if="items">
    <Waypoint v-if="loader" @change="waypointHeader" />
    <div class="selector" v-if="loader" :class="{ show: showSelector }">
      <div class="trigger" @click="showSelectorMenu = !showSelectorMenu">
        <span>Zeitraum: {{ selection[selected].title }}1</span>
      </div>
      <div v-if="showSelectorMenu" class="menuSelector">
        <template v-for="(selector, index) in selection" :key="index">
          <div class="menu" v-if="index != selected" @click="select(index)">
            <span>{{ selector.title }}</span>
          </div>
        </template>
      </div>
    </div>

    <template v-for="item in items" :key="item.id">
      <ProgrammItem :item="item" :full="false" />
    </template>
    <Loader
      @load="load"
      v-if="loader && items.length < meta.count && selected == 0"
    />
  </section>
  <!-- <Waypoint v-if="loader" @change="waypointFooter" /> -->
</template>

<script>
import { Waypoint } from "vue-waypoint";
import { mapActions } from "vuex";
import ProgrammItem from "@/components/ProgrammItem.vue";
import Loader from "@/components/Loader.vue";
import dayjs from "dayjs";

export default {
  name: "NewsContainer",
  props: ["loader", "limit"],
  components: {
    ProgrammItem,
    Loader,
    Waypoint,
  },
  data() {
    return {
      today: dayjs().format("YYYY-MM-DD"),
      items: undefined,
      meta: undefined,
      page: 1,
      listener: undefined,
      showSelector: false,
      showSelectorMenu: false,
      selection: [
        { title: "8. Juli - 13. August 2022", query: "" },
        { title: "8./9. Juli 2022", end: "2022-07-09", query: "" },
        { title: "15./16. Juli 2022", end: "2022-07-16", query: "" },
        { title: "22./23. Juli 2022", end: "2022-07-23", query: "" },
        { title: "29./30. Juli 2022", end: "2022-07-30", query: "" },
        { title: "5./6. August 2022", end: "2022-08-06", query: "" },
        { title: "12./13. August 2022", end: "2022-08-13", query: "" },
      ],
      selected: 0,
    };
  },
  methods: {
    ...mapActions(["db"]),
    fetch(push) {
      let payload = {
        o: { start: 1 },
        l: this.limit,
        p: this.page,
        q: { start: { $gte: new Date() } },
      };

      if (this.selected != 0) {
        let start = dayjs(this.selection[this.selected].end)
          .subtract(1, "day")
          .format("YYYY-MM-DD 00:00:01");

        let end = dayjs(this.selection[this.selected].end).format(
          "YYYY-MM-DD 23:59:00"
        );
        payload.l = 10;
        payload.p = 1;
        payload.q = { start: { $gte: start, $lte: end } };
      }

      this.db({
        table: "events",
        payload: payload,
      })
        .then((res) => {
          if (push) {
            res.data.results.forEach((item) => this.items.push(item));
            this.meta = res.data.meta;
          } else {
            this.meta = res.data.meta;
            this.items = res.data.results;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    load() {
      this.page++;
      this.fetch(true);
    },
    select(id) {
      this.selected = id;
      this.showSelector = false;
      this.showSelectorMenu = false;
      window.scroll(0, 0);
      this.fetch();
    },
    waypointHeader(e) {
      if (e.going == "OUT" && e.direction == "UP") this.showSelector = true;
      if (e.going == "IN" && e.direction == "DOWN" && window.scrollY > 10)
        this.showSelector = false;
    },
    waypointFooter(e) {
      if (e.going == "IN" && e.direction == "UP") this.showSelector = false;
      if (e.going == "OUT" && e.direction == "DOWN" && window.scrollY > 10)
        this.showSelector = true;
    },
  },
  mounted() {
    if (this.$route.params.end) {
      let index = this.selection.findIndex(
        (el) => el.end == this.$route.params.end
      );
      this.select(index);
    } else {
      this.fetch(false);
    }
  },
};
</script>

<style lang="css" scoped>
.selector {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: var(--orange);
  color: black;
  font-weight: bold;
  width: 100%;
  transform: translateY(-100%);
  transition: transform 0.4s;
  line-height: 1.2rem;
  padding: 12px 0;
  font-size: 1.2rem;
  z-index: 9999999999999;
}

.selector.show {
  transform: translateY(0);
}

.selector .menuSelector {
  margin-top: 12px;
  font-size: 1rem;
  line-height: 2.4rem;
}

.selector .trigger,
.selector .menu {
  width: calc(100%);
  user-select: none;
  cursor: pointer;
  text-align: left;
  position: relative;
  text-align: center;
}

.selector .trigger span,
.selector .menu span {
  position: relative;
  padding: 0 6px;
  line-height: 1.8rem;
}

@media screen and (min-width: 900px) {
  .selector {
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: var(--orange);
    color: black;
    font-weight: bold;
    width: 100%;
    transform: translateY(-100%);
    transition: transform 0.4s;
    line-height: 1.8rem;
    padding: 12px 0;
    font-size: 1.8rem;
    z-index: 9999999999999;
  }

  .selector .menuSelector {
    margin-top: 12px;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .selector .trigger,
  .selector .menu {
    width: calc(100%);
    user-select: none;
    cursor: pointer;
    text-align: left;
    position: relative;
    text-align: center;
  }

  .selector .trigger span,
  .selector .menu span {
    position: relative;
    padding: 0 6px;
    line-height: 1.8rem;
  }
}
</style>
