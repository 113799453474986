<template>
  <section class="programm">
    <div class="title">
      <div class="date">{{ datum }}</div>
      <h2>{{ item.title }}</h2>
    </div>

    <div class="imageContainer">
      <Image v-if="image" :id="image" :title="item.title" />

      <div class="tags">
        <span>{{ item.place_name }}</span>
        <span>{{ item.category_name }}</span>
      </div>
    </div>

    <div v-html="item.content" class="content" />
  </section>
</template>

<script>
import dayjs from "dayjs";
require("dayjs/locale/de");
dayjs.locale("de");

import Image from "@/components/Image.vue";
export default {
  props: ["item", "full", "showNext"],
  components: {
    Image,
  },
  computed: {
    datum() {
      return (
        dayjs(this.item.start).format("dddd, D. MMMM YYYY - HH:mm") + " Uhr"
      );
    },
    text() {
      const text = this.item.content.split("</p>")[0];
      return text;
    },
    image() {
      if (this.item.images != "") return this.item.images.split(",")[0];
      else return false;
    },
    images() {
      if (this.item.images != "" && this.item.images.split(",").length > 1)
        return this.item.images.split(",").slice(1);
      else return false;
    },
  },
};
</script>

<style lang="css">
.programm .tags {
  font-size: 0.8rem;
  line-height: 0.8rem;
  max-width: 100%;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.programm .tags span {
  display: inline-block;
  background: #ccc;
  color: white;
  margin-right: 6px;
  white-space: nowrap;
  padding: 6px 8px;
  font-weight: 600;
}

.programm {
  padding: 12px;
}

.programm:nth-of-type(odd) {
  background: var(--grey);
}

.programm:nth-of-type(even) {
  background: var(--grey2);
}

.programm .tags {
  font-size: 0.7rem;
  line-height: 0.7rem;
  margin-top: 6px;
  margin-bottom: 0;
}

.programm .title .date {
  font-size: 1.2rem;
  margin-bottom: 6px;
}

.programm h2 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 12px;
  font-weight: 400;
  max-width: 100%;
}

.content p:first-child {
  font-weight: 600 !important;
}

.programm .imageContainer {
  border: 6px solid white;
  background: white;
  margin-bottom: 0px;
  box-sizing: border-box;
  margin-bottom: 24px;
}

@media screen and (min-width: 900px) {
  .programm {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas:
      "title title"
      "image content";
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    padding: 24px;
    border-bottom: 3px solid white;
  }

  .programm .title {
    grid-area: title;
    box-sizing: border-box;
  }

  .programm .title .date {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .programm h2 {
    font-size: 2.8rem;
    line-height: 3.4rem;
    margin-bottom: 12px;
    font-weight: 400;
    max-width: 70%;
  }

  .programm .image {
    grid-area: image;
    background: none;
    border: none;
  }

  .programm .imageContainer {
    border: none;
    background: none;
  }

  .programm .content {
    grid-area: content;
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 24px);
  }
}
</style>
